import ContractingTypes from "./contracting.types";

const INITIAL_STATE = {
    activities: [],
    activity: {}
}

const contractingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ContractingTypes.SET_CONTRACTING_ACTIVITIES:
            return {
                ...state,
                activities: action.payload
            }
        case ContractingTypes.SET_CONTRACTING_ACTIVITY:
            return {
                ...state,
                activity: action.payload
            }
        case ContractingTypes.CLEAR_CONTRACTING_ACTIVITY:
            return {
                ...state,
                activity: {}
            }
        default: 
            return state;
    }
    
}

export default contractingReducer;