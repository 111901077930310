import EvaluationTypes from './evaluation.types';
import { onChangeResponderEvaluationUtil, buildResponderEvaluationUtil, removeEvaluationStateByIndexUtil, removeResponderFileUtil, updateEvaluationStateFieldUtil, updateResponderPriceUtil, uploadResponderFileUtil, selectResponderAwardUtil, EvaluationSettingsUtil, RemoveUserFromEvaluationUtil } from './evaluation.utils';

const RESPONDER_TEMPLATE = {
    name: '',
    financial: {
        price: '',
        lowest: false
    }, 
    responderEvaluation: [],
    file: [],
    total: {
      awarded: false,
      score: ''
    }
}

const EVALUATORS_TEMPLATE = {userId: '', score: '', isNew: true};

const LIST = {
    evaluators: EVALUATORS_TEMPLATE,
    responders: RESPONDER_TEMPLATE,
    evaluation: [],
}

const SETTINGS = {
  viewSummary: false,
  isNew: true
}
const INITIAL_STATE = {
    evaluators: [LIST.evaluators],
    responders: [LIST.responders],
    evaluation: [LIST.evaluation],
    RemoveUserFromEvaluation: {
      responders: [],
      evaluators: []
    },
    criteria: [],
    settings: SETTINGS
};


const evaluationReducer = (state = INITIAL_STATE, action) => {
  let newList = {};
  switch (action.type) {
    case EvaluationTypes.BUILD_EVALUATION_CRITERIA:
      return {
        ...state,
        responders: buildResponderEvaluationUtil(state.responders, state.criteria, state.evaluators)
      }
      case EvaluationTypes.ADD_RESPONDERS_EVALUATION:
        return {
          ...state,
          responders: updateResponderPriceUtil(action.payload, {criteria: state.criteria})
        }
        case EvaluationTypes.ADD_EVALUATORS:
          return {
            ...state,
            evaluators: action.payload
          }
    case EvaluationTypes.ADD_EVALUATION_CRITERIA:
      return {
        ...state,
        criteria: action.payload
      }
    case EvaluationTypes.ADD_CONTENT:
      return {
        ...state
      }
    case EvaluationTypes.ADD_EVALUATION_STATE:
      newList = Object.assign({}, LIST[action.payload.list]);
      if(action.payload.list === 'responders') {
          newList = {
            name: '',
            financial: {
                price: '',
                lowest: false
            }, 
            responderEvaluation: [],
            file: [],
            total: {
              awarded: false,
              score: ''
            }
        }
      }
      return {
        ...state,
        [action.payload.target]: [...state[action.payload.target], newList]
      }

    case EvaluationTypes.REMOVE_EVALUATION_STATE:
      if(!state.settings.isNew) {
        state.RemoveUserFromEvaluation = RemoveUserFromEvaluationUtil(state.RemoveUserFromEvaluation, action.payload);
      }
      return {
        ...state,
        [action.payload.target]: removeEvaluationStateByIndexUtil(state[action.payload.target], action.payload.index)
      }

    case EvaluationTypes.UPDATE_EVALUATION_STATE_FIELD: 
      const target = action.payload.target;
      if(target === 'evaluators' && !state.isNew) {
        const evaluatorId = parseInt(action.payload.field[1]);
        const userId = state.evaluators[action.payload.field[2]].userId;
        let removeEvaluator = state.RemoveUserFromEvaluation.evaluators.filter(e => parseInt(e) !== evaluatorId);
        
        if(!state.evaluators[action.payload.field[2]].isNew) { 
          if(evaluatorId && userId) {
            removeEvaluator.push(userId);
            let
              index = action.payload.field[2],
              newArray = [...state.evaluators];
              newArray[index] = { ...newArray[index], isNew: true }
              state.evaluators = newArray;
          }
        }
        state.RemoveUserFromEvaluation.evaluators =  removeEvaluator;
      }
      return {
        ...state,
        [action.payload.target]: updateEvaluationStateFieldUtil(state[action.payload.target], action.payload)
      }
    case EvaluationTypes.UPDATE_RESPONDER_PRICE:
      return {
        ...state,
        responders: updateResponderPriceUtil(state.responders, action.payload)
      }

      case EvaluationTypes.UPLOAD_RESPONDER_FILE:
        return {
          ...state,
          responders: uploadResponderFileUtil(state.responders, action.payload)
        }
      case EvaluationTypes.REMOVE_RESPONDER_FILE:
        return {
          ...state,
          responders: removeResponderFileUtil(state.responders, action.payload)
        }
    
        case EvaluationTypes.ON_CHANGE_RESPONDER_EVALUATION:
          return {
            ...state,
            responders: onChangeResponderEvaluationUtil(state.responders, action.payload)
          }

          case EvaluationTypes.SET_RESPONDER_AWARD:
            return {
              ...state,
              responders: selectResponderAwardUtil(state.responders, action.payload)
            }
            
        

        /////// Settings /////

          case EvaluationTypes.CHANGE_SETTINGS:
            return {
              ...state,
              settings: EvaluationSettingsUtil(state.settings, action.payload)
            }
    default:
      return state;
  }
}

export default evaluationReducer;