import { createLogic } from "redux-logic";
import { API } from "src/config/api";
import IntranetTypes from './intranet.types';

export const getProjectActivitiesLogic = createLogic({
  type: IntranetTypes.GET_PROJECT_ACTIVITIES,
  latest: true, // take latest only

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(API.PROJECT_ACTIVITIES(1))
      .then(resp => resp.data) // use data property of payload
      .then(users =>
        dispatch({
          type: IntranetTypes.SET_PROJECT_ACTIVITIES,
          payload: users
        })
      )
      .catch(err => {
        console.error(err);
      })
      .then(() => done());
  }
});

export const getCountriesLogic = createLogic({
    type: IntranetTypes.GET_COUNTRIES,
    latest: true, // take latest only
    process({ httpClient }, dispatch, done) {
      fetch(API.COUNTRIES)
        .then(res => res.json())
        .then(
            result => 
            dispatch({
              type: IntranetTypes.SET_COUNTRIES,
              payload: result
            })
        ) 
        .catch(err => {
          console.error(err);
        })
        .then(() => done());
    }
  });

export const getIntranetByTypeLogic = createLogic({
    type: IntranetTypes.GET_INTRANET_BY_TYPE,
    latest: true, // take latest only
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient
      .get(API.GET_INTRANET_TYPE(action.payload))
      .then(resp => resp.data) // use data property of payload
      .then(newData =>
        dispatch({
          type: IntranetTypes.SET_INTRANET_BY_TYPE,
          payload: {target: action.payload, data: newData}
        })
      )
      .catch(err => {
        console.error(err);
      })
      .then(() => done());
    }
  });

export const  getCurrencyRatesLogic =  createLogic({
    type: IntranetTypes.GET_CURRENCY_RATES,
    latest: true, // take latest only
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient
      .get(API.GET_CURRENCY_RATE)
      .then(resp => resp.data) // use data property of payload
      .then(currencyRates =>        
        dispatch({
          type: IntranetTypes.SET_CURRENCY_RATES,
          payload: currencyRates
        })
      )
      .catch(err => {
        console.error(err);
      })
      .then(() => done());
    }
  });


