const EvaluationTypes = {
    ADD_CONTENT: 'ADD_CONTENT',
    ADD_EVALUATION_STATE: 'ADD_EVALUATION_STATE',
    REMOVE_EVALUATION_STATE: 'REMOVE_EVALUATION_STATE',
    UPDATE_EVALUATION_STATE_FIELD: 'UPDATE_EVALUATION_STATE_FIELD',
    UPDATE_RESPONDER_PRICE: 'UPDATE_RESPONDER_PRICE',
    UPLOAD_RESPONDER_FILE: 'UPLOAD_RESPONDER_FILE',
    REMOVE_RESPONDER_FILE: 'REMOVE_RESPONDER_FILE',
    UPDATE_RESPONDER_EVALUATION: 'UPDATE_RESPONDER_EVALUATION',
    ADD_EVALUATION_CRITERIA: 'ADD_EVALUATION_CRITERIA',
    BUILD_EVALUATION_CRITERIA: 'BUILD_EVALUATION_CRITERIA',
    ON_CHANGE_RESPONDER_EVALUATION: 'ON_CHANGE_RESPONDER_EVALUATION',
    SET_RESPONDER_AWARD: 'SET_RESPONDER_AWARD',
    ADD_RESPONDERS_EVALUATION: 'ADD_RESPONDERS_EVALUATION',
    ADD_EVALUATORS: 'ADD_EVALUATORS',
    CHANGE_SETTINGS: 'CHANGE_SETTINGS'
}

export default EvaluationTypes;