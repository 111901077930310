import { API } from "../../config/api";
import { createLogic } from "redux-logic";
import ProcurementTypes from './procurement.types';
import EvaluationTypes from "../evaluation/evaluation.types";

export const getProcessCriteriaLogic = createLogic({
    type: ProcurementTypes.FETCH_CRITERIA,
    latest: true,
    process({ httpClient, getState, action }, dispatch, done) {
      httpClient.get(API.PROC_REQ_CRITERIA(action.payload))
      .then(resp => resp.data)
      .then(async criteria => {
        dispatch({ type: ProcurementTypes.ADD_TO_STATE, payload:{target: 'criteria',  list: criteria} })
        dispatch({ type: ProcurementTypes.SET_IS_NEW, payload:false })
        dispatch({ type: EvaluationTypes.ADD_EVALUATION_CRITERIA , payload: criteria })
        const evaluation = await httpClient.get(API.PROC_RESPONDERS_EVALUATION(action.payload));
        if(evaluation.data && evaluation.data.data && evaluation.data.data.responders && evaluation.data.data.responders.length) {
          dispatch({ type: EvaluationTypes.CHANGE_SETTINGS, payload: {target: 'isNew', value: false}})
          dispatch({ type: EvaluationTypes.ADD_EVALUATORS, payload: evaluation.data.data.evaluators})
          dispatch({ type: EvaluationTypes.ADD_RESPONDERS_EVALUATION, payload: evaluation.data.data.responders})
          dispatch({ type: EvaluationTypes.BUILD_EVALUATION_CRITERIA })
        }
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
  });

export const getBudgetLineOwnerLogic = createLogic({
    type: ProcurementTypes.GET_BUDGET_LINE_OWNER,
    latest: true,
    process({ httpClient, getState, action }, dispatch, done) {
      httpClient.get(API.GET_BUDGET_LINE_OWNER(action.payload))
      .then(resp => resp.data)
      .then(async budgetLine => {
        console.log(budgetLine)
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
  });