import Axios from 'axios';
import { createStore, applyMiddleware } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { persistStore } from 'redux-persist';

import logger from 'redux-logger';

import rootReducer from './root-reducer';
import { updateUserProfileLogic } from './user/user.logic';
import { getCountriesLogic, getProjectActivitiesLogic, getIntranetByTypeLogic, getCurrencyRatesLogic } from './intranet/intranet.logic';
import { getBudgetLineOwnerLogic, getProcessCriteriaLogic } from './procurement/procurement.logic';
import { getContractingActivitiesLogic, getContractingActivityLogic } from './contracting/contracting.logic';
import { deletePaymentFileLogic, getEntityInfoLogic, getFinanceOfficersLogic, getPaymentCommentsLogic, getPaymentFilesLogic, getPaymentLogic, getPaymentRequestLogic, getPaymentsLogic, getPaymentsNewRequestIDLogic, initiatePaymentRequestLogic } from './payment/payment.logic';

const deps = {
    httpClient: Axios
};

const arrLogic = [
        updateUserProfileLogic,
        getProjectActivitiesLogic,
        getIntranetByTypeLogic,
        getProcessCriteriaLogic,
        getContractingActivitiesLogic,
        getContractingActivityLogic,
        getCountriesLogic,
        getPaymentsLogic,
        getPaymentLogic,
        getCurrencyRatesLogic,
        getPaymentsNewRequestIDLogic,
        initiatePaymentRequestLogic,
        getPaymentFilesLogic,
        deletePaymentFileLogic,
        getEntityInfoLogic,
        getPaymentCommentsLogic,
        getPaymentRequestLogic,
        getFinanceOfficersLogic,
        getBudgetLineOwnerLogic
    ];

const logicMiddleware = createLogicMiddleware(arrLogic, deps);

const middlewares = [logger, logicMiddleware];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store);

// export default {store, persistor}; 