const PaymentTypes = {
    GET_PAYMENTS: 'GET_PAYMENTS',
    GET_PAYMENT: 'GET_PAYMENT',
    ADD_TO_PAYMENTS: 'ADD_TO_PAYMENTS',
    ADD_TO_PAYMENT: 'ADD_TO_PAYMENT',
    INITIATED_PAYMENT: 'INITIATED_PAYMENT',
    INITIATE_PAYMENT_REQUEST: 'INITIATE_PAYMENT_REQUEST',
    UPDATE_INITIATED_PAYMENT: 'UPDATE_INITIATED_PAYMENT',
    UPDATE_REQUESTER_INFO: 'UPDATE_REQUESTER_INFO',
    ON_CHANGE_PAYMENT_ITEM: 'ON_CHANGE_PAYMENT_ITEM',
    SET_PAYMENT_CURRENCIES: 'SET_PAYMENT_CURRENCIES',
    SET_CUSTOM_CURRENCIES: 'SET_CUSTOM_CURRENCIES',
    SET_EDIT_CUSTOM_CURRENCY: 'SET_EDIT_CUSTOM_CURRENCY',
    RESTORE_CURRENCY_RATE: 'RESTORE_CURRENCY_RATE',
    ADD_PAYMENT_ITEM: 'ADD_PAYMENT_ITEM',
    REMOVE_PAYMENT_ITEM: 'REMOVE_PAYMENT_ITEM',
    GET_PAYMENT_REQUEST_ID: 'GET_PAYMENT_REQUEST_ID',
    UPDATE_REQUEST_ID: 'UPDATE_REQUEST_ID',
    GET_PAYMENT_FILES: 'GET_PAYMENT_FILES',
    SET_PAYMENT_FILES: 'SET_PAYMENT_FILES',
    DELETE_PAYMENT_FILE: 'DELETE_PAYMENT_FILE',
    UNMOUNT_COMPONENT: 'UNMOUNT_COMPONENT',
    ADD_COMMENT: 'ADD_COMMENT',
    DELETE_COMMENT: 'DELETE_COMMENT',
    ON_CHANGE_COMMENT_VALUE: 'ON_CHANGE_COMMENT_VALUE',
    SET_ENTITY_INFO: 'SET_ENTITY_INFO',
    GET_ENTITY_INFO: 'GET_ENTITY_INFO',
    REFRESH_INITIATED_PAYMENT: 'REFRESH_INITIATED_PAYMENT',
    SET_COMMENTS: 'SET_COMMENTS',
    UPDATE_COMMENTS_UUID: 'UPDATE_COMMENTS_UUID',
    GET_PAYMENT_COMMENTS: 'GET_PAYMENT_COMMENTS',
    SET_PAYMENT_APPROVAL: 'SET_PAYMENT_APPROVAL',
    GET_PAYMENT_REQUEST: 'GET_PAYMENT_REQUEST',
    GET_FINANCE_OFFICERS: 'GET_FINANCE_OFFICERS',
    SET_FINANCE_OFFICERS: 'SET_FINANCE_OFFICERS',
    SET_PAYMENT_ENDS_ON: 'SET_PAYMENT_ENDS_ON',
    ON_CHANGE_TAX: 'ON_CHANGE_TAX',
}

export default PaymentTypes;