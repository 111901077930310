import NotificationTypes from "./notification.types";

export const addNotification = (notification) => ({
    type: NotificationTypes.ADD_NOTIFICATION,
    payload: notification
});


export const clearNotifications = () => ({
    type: NotificationTypes.CLEAR_NOTIFICATION
})