
export const removeEvaluationStateByIndexUtil = (state, index) => {
    let newArray = [...state];
    newArray.splice(index, 1);
    return newArray;
}

export const updateEvaluationStateFieldUtil = (state, target) => {
    let name = target.field[0], value = target.field[1], index = target.field[2], newArray = [...state];
    newArray[index] = { ...newArray[index], [name]: value }
    return newArray;
}

export const updateResponderPriceUtil = (responders, responder) => {
    let { value, index, criteria } = responder;
    const newArray = [...responders];
    // let lowest = false;
    if(value) {
        value = parseInt(value); 
        newArray[index].financial = { ...newArray[index].financial, price: value };
    }    

    let lowestPrice = Math.min(...newArray.map(item => item.financial.price));
    // if(value <= lowestPrice) {lowest = true };
    // newArray[index].financial = { ...newArray[index].financial, lowest };
    newArray.map((res) => {
        res.financial.lowest = false;
        if(res.financial.price === lowestPrice) res.financial.lowest = true;
        return res;
    })
    return calculateFinancial(responders, criteria, lowestPrice);
}

export const uploadResponderFileUtil = (responders, fileArray) => {
    let index = fileArray.itemId, file = fileArray.response;
    let newArray = [...responders];
    newArray[index] = { ...newArray[index], file: file }
    return newArray;
}

export const removeResponderFileUtil = (responders, index) => {
    let newArray = [...responders];
    newArray[index] = { ...newArray[index], file: {} }
    return newArray;
}

export const buildResponderEvaluationUtil = (responders, criteriaList, evaluators) => {
    const newArray = [...responders];
    const newCriteriaList = criteriaList.filter((criteria) => criteria.label !== 'Financial');
    let countEvaluators = evaluators.length;
    newArray.map((responder) => { 
        responder.total = {
            awarded: responder.total.awarded
        };
        newCriteriaList.map((criteria, index) => { 
            let criteriaId = 'criteria_'+(criteria.id.toString());
            let criteriaWeight = criteria.weight;
            if(!responder.total[criteriaId]) responder.total[criteriaId] = {};
            responder.total[criteriaId].criteriaId = criteria.id;

            if(!responder.responderEvaluation[criteriaId]) responder.responderEvaluation[criteriaId] = {};
            criteria.sub_criteria.map(sub => { 
                let subCriteriaId = 'sub_criteria_'+(sub.id.toString());

                if(!responder.total[criteriaId][subCriteriaId]) responder.total[criteriaId][subCriteriaId] = {};
                responder.total[criteriaId][subCriteriaId] = {
                    subCriteriaId: sub.id,
                    score: ''
                }
                    

                if(!responder.responderEvaluation[criteriaId][subCriteriaId]) responder.responderEvaluation[criteriaId][subCriteriaId] = {};
                evaluators.forEach((evaluator) => {
                    let evaluatorId = 'evaluator_'+ (evaluator.userId.toString());
                    if(!responder.responderEvaluation[criteriaId][subCriteriaId][evaluatorId]) {
                        responder.responderEvaluation[criteriaId][subCriteriaId][evaluatorId] = {
                            userId: '',
                            score: ''
                        };
                    }
                    // responder.responderEvaluation[criteriaId][evaluatorId].score = '';
                })
                return sub;
            })
            let sumSubCriteriaScore = 0;

            Object.keys(responder.responderEvaluation[criteriaId]).map((criteriaKey, responderCriteriaIndex) => {
                let subCriteriaId = criteriaKey.replace('sub_criteria_', '');
                subCriteriaId = parseInt(subCriteriaId);
                let subCriteria = criteria.sub_criteria.filter(c => c.id === subCriteriaId);
                subCriteria = subCriteria[0];
                let subCriteriaWeight = subCriteria.weight;

                let sumResponderScore = 0;
                let newResponder = [];
                Object.keys(responder.responderEvaluation[criteriaId][criteriaKey]).map(key => {
                    let newKey = key.replace('evaluator_', '');
                    newKey = parseInt(newKey);
                    let getResponder = evaluators.filter(res => parseInt(res.userId) === newKey);
                    if(!getResponder.length) return key;
                    newResponder[key] = responder.responderEvaluation[criteriaId][criteriaKey][key]
                    let score = responder.responderEvaluation[criteriaId][criteriaKey][key].score;
                    responder.responderEvaluation[criteriaId][criteriaKey][key].userId = newKey;
                    if(score) {
                        sumResponderScore = sumResponderScore + parseInt(score);
                    }
                    return key;
                })

                responder.responderEvaluation[criteriaId][criteriaKey] = newResponder;

                let avg = sumResponderScore / countEvaluators;
                let subScore = avg * 25; 
                subScore = (subScore * subCriteriaWeight) / 100;
                // if(subScore % 1 !== 0) {
                // }

                sumSubCriteriaScore = sumSubCriteriaScore + subScore;
                if((responderCriteriaIndex + 1) === criteria.sub_criteria.length) {
                    sumSubCriteriaScore = (sumSubCriteriaScore * criteriaWeight) / 100; 
                }
                subScore = (subScore * criteriaWeight) / 100;
                subScore = parseFloat(subScore.toFixed(2));

                sumSubCriteriaScore = parseFloat(sumSubCriteriaScore.toFixed(2));

                
                responder.responderEvaluation[criteriaId][criteriaKey].total = subScore;
                responder.total[criteriaId].score = sumSubCriteriaScore;
                responder.total[criteriaId][criteriaKey].score = subScore;
                if(!responder.total.score) responder.total.score = 0;
                let responderTotalEvaluation = responder.total.score + subScore;

                responderTotalEvaluation = parseFloat(responderTotalEvaluation.toFixed(2));

                responder.total.score = responderTotalEvaluation;
                return criteriaKey;
            });
            return criteria;
        }) 
        return responder;
    }); 
    return newArray;
}
export const changedEvaluator = (responders, evaluator) => {
    console.log('============START');
    console.log(responders);
    console.log(evaluator);
    if(!evaluator) return;
    let evaluatorId = `evaluator_${evaluator.toString()}`;
    let newArray = [...responders];
    newArray.map((responder) => {
        return Object.keys(responder.responderEvaluation).map((evaluationKey) => {
            let responderEvaluators = {};
            Object.keys(responder.responderEvaluation[evaluationKey]).map((evaluator) => {
                if(evaluator !== evaluatorId) {
                    responderEvaluators[evaluator] = responder.responderEvaluation[evaluationKey][evaluator];
                }
                return evaluator;
            });
            return responder.responderEvaluation = responderEvaluators;
        });
    });
    return newArray;
}

export const calculateFinancial = (responders, criteria, lowestPrice) => {
    let newArray = [...responders];
    let criteriaFinancial = criteria.filter(c => c.label === 'Financial');
    criteriaFinancial = criteriaFinancial[0];
    let financialId = criteriaFinancial.sub_criteria[0].id;
    if(!lowestPrice) {
        lowestPrice = Math.min(...responders.map((item) => item.financial.price));
    }
    newArray.forEach((responder, i) => {
        let price = responder.financial.price;
        if(!price) return;
        let score = (lowestPrice / price) * 100;
        score = score * criteriaFinancial.weight / 100;
        score = parseFloat(score.toFixed(2));
        responders[i].financial.score = score;
        responders[i].financial.id = financialId;
    });

    return newArray;
}

export const onChangeResponderEvaluationUtil = (responders, responder) => {
    const {criteriaId, subCriteriaId, userId, score, index} = responder;
    const criteriaIdString = `criteria_${criteriaId.toString()}`;
    const userIdString = `evaluator_${userId.toString()}`;
    const subCriteriaIdString = `sub_criteria_${subCriteriaId.toString()}`
    const newArray = [...responders];
    newArray[index].responderEvaluation[criteriaIdString][subCriteriaIdString][userIdString].score = score;
    return responders;
}

export const selectResponderAwardUtil = (responders, selectedResponder) => {
    const { responder, numberOfContracts, index } = selectedResponder;
    const newArray = [...responders];

    const awarded = newArray.filter(awardedResponders => awardedResponders.total.awarded === true);
    if(awarded.length) {
        const isSameResponder = awarded.filter(awardedResponder => awardedResponder.name === responder.name);
        if(isSameResponder.length) { 
            newArray[index].total.awarded = false; 
        } else {
            if(awarded.length >= numberOfContracts && !responder.total.awarded) {
                return newArray;
            } else {
                newArray[index].total.awarded = !newArray[index].total.awarded;
            }
        }
    } else {
        newArray[index].total.awarded = !newArray[index].total.awarded;
    }
    return newArray;
}

export const EvaluationSettingsUtil = (settings, action) => {
    const { target, value } = action;
    settings[target] = value;
    return settings;
}


export const RemoveUserFromEvaluationUtil = (RemoveUserFromEvaluation, action) => {
    const { target, id } = action;
    if(!id) return RemoveUserFromEvaluation;
    let removeId = id;
    if(target === 'responders') {
        if(id) {
            let isFoundId = RemoveUserFromEvaluation[target].filter(res => res === removeId);
            if(isFoundId.length) {
                console.log(isFoundId);
                return RemoveUserFromEvaluation;
            }
        }
      }
      RemoveUserFromEvaluation[target].push(removeId);

    return RemoveUserFromEvaluation;
}