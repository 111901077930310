import NotificationTypes from './notification.types';
import { addNotificationToList } from './notification.utils';

const NOTIFICATION_TEMPLATE = {
  'static': [],
  'top-left': [],
  'top-center': [],
  'top-right': [],
  'top-full': [],
  'bottom-left': [],
  'bottom-center': [],
  'bottom-right': [],
  'bottom-full': [],
};

const INITIAL_STATE = {
    list: NOTIFICATION_TEMPLATE,
};

const notificationReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

      case NotificationTypes.ADD_NOTIFICATION:
        return {
          ...state,
          list: addNotificationToList(state.list, action.payload)
        }

      case NotificationTypes.CLEAR_NOTIFICATION: 
        return {
          ...state,
          list: {
            'static': [],
            'top-left': [],
            'top-center': [],
            'top-right': [],
            'top-full': [],
            'bottom-left': [],
            'bottom-center': [],
            'bottom-right': [],
            'bottom-full': [],
          }
        }

        default: 
        return state;
    }

}

export default notificationReducer;