import fx from 'money';
import moment from 'moment';

export const updateInitiatedPaymentUtils = (newInitiatedPayment, payment) => {
    payment = payment[0];
    const activity = payment.payment_contract.activity;
    let paymentItems = payment.paymentItems;
    if (!paymentItems) {
        paymentItems = [
            {
                description: `Contract ID: ${payment.contract_id}`,
                budgetline_id: activity.budgetline_id,
                payment_value: payment.payment_value,
                reference: "",
                currency_name: payment.payment_contract.currency_name,
                rate: "",
                total: payment.payment_value,
            } 
        ]
    } 
    
    newInitiatedPayment.has_contract = true;
    newInitiatedPayment.contract_id = payment.contract_id;
    newInitiatedPayment.payment_id = payment.id;
    newInitiatedPayment.proc_request_id = payment.payment_contract.activity.id;
    newInitiatedPayment.currency_name = payment.payment_contract.currency_name;
    newInitiatedPayment.project_id = activity.project_activity.project_id;
    newInitiatedPayment.project_currency_name = activity.project_activity.project.currency_name;
    newInitiatedPayment.entity_id = payment.payment_contract.entity_id;
    newInitiatedPayment.total_value = parseFloat(payment.payment_value);
    newInitiatedPayment.paymentItems = paymentItems;
    newInitiatedPayment.request_id = payment.payment_request ? payment.payment_request.request_id : '';
    newInitiatedPayment.comments = [];
    newInitiatedPayment.comments_uuid = payment.payment_request && payment.payment_request.comments_uuid ? payment.payment_request.comments_uuid : '';
    // newInitiatedPayment.documents = [];
    newInitiatedPayment.participated= [];
    return newInitiatedPayment;
};

export const refreshInitiatedPaymentUtils = (initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    console.log(newInitiatedPayment);
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const setCommentsUtils = (comments, initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    newInitiatedPayment.comments = comments;
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const updateInitiatedPaymentRequesterUtils = (initiatedPayment, action, currenciesRate, customCurrenciesRate) => {
    initiatedPayment.payment_request_number = action.payment_request_number;
    initiatedPayment.requester_id = action.requester_id;
    initiatedPayment.requester_title = action.requester_title;
    initiatedPayment.order_date = action.order_date;
    initiatedPayment.fullname = action.fullname;
    initiatedPayment.participated = [{user_id: action.requester_id}];
    return updateNewPaymentCurrency(initiatedPayment, currenciesRate, customCurrenciesRate);
}

export const updateInitiatedPaymentItemsUtils = (initiatedPayment, currenciesRate, customCurrenciesRate, action) => {
    let {e, index } = action;
    let arr = e;

    if(index === undefined) {
        arr = action;
    }

    let { name, value } = arr.target;
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    if(index === undefined) {
        newInitiatedPayment[name] = value;
    } else {
        newInitiatedPayment.paymentItems[index][name] = value;
        newInitiatedPayment.paymentItems[index]['total'] = value; 
    }
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate, customCurrenciesRate);
}

export const updateCustomCurrenciesUtils = (action, initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    let name, value;
    if(!action.target) {
        value = initiatedPayment.customCurrenciesRate[action] ? initiatedPayment.customCurrenciesRate[action] : currenciesRate[action];
        name = action;
    } else {
        name = action.target.name;
        value = action.target.value;
    }
    newInitiatedPayment.customCurrenciesRate[name] = value;
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const restoreCurrencyRateUtils = (action, initiatedPayment, currenciesRate) => {
    let value = currenciesRate[action],
        name = action;
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
        newInitiatedPayment.customCurrenciesRate[name] = value;
        return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const addPaymentItemUtils = (initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    let TEMPLATE = 
    {
        description: '',
        budgetline_id: '',
        payment_value: '',
        reference: '',
        currency_name: '',
        rate: '',
        total: '',
    }
    newInitiatedPayment.paymentItems.push(TEMPLATE);
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}
export const removePaymentItemUtils = (index, initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    if(newInitiatedPayment.paymentItems[index].id) {
        if(!newInitiatedPayment.removePayments) {
            newInitiatedPayment.removePayments = [newInitiatedPayment.paymentItems[index].id]
        } else {
            newInitiatedPayment.removePayments.push(newInitiatedPayment.paymentItems[index].id)
        }
    }
    newInitiatedPayment.paymentItems.splice(index, 1);
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const setPaymentFilesUtils = (data, initiatedPayment) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    newInitiatedPayment.documents = data;
    return newInitiatedPayment;
    // return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const setPaymentEndsOnUtils = (userId, initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    newInitiatedPayment.payment_ends_on = newInitiatedPayment.payment_ends_on === userId ? null : userId;
    return newInitiatedPayment;
    // return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const calculateTaxUtils = (initiatedPayment, newValue) => {
    const {field, currentValue} = newValue;
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    let {total_value} = newInitiatedPayment;
    newInitiatedPayment[field] = currentValue;
    let taxRate = field === 'tax_rate' ? currentValue : newInitiatedPayment.tax_rate;
    let taxValue = field === 'tax_value' ? currentValue : newInitiatedPayment.tax_value;
    let netAmount = 0;
    if(!newInitiatedPayment.tax_type) return newInitiatedPayment;

    if(newInitiatedPayment.tax_type === 'FIXED') {
        netAmount = total_value - taxRate;
        taxValue = taxRate;
    } else {
        taxValue = ((total_value * taxRate) / 100).toFixed(3);
        netAmount = total_value - taxValue;
    }

    newInitiatedPayment.net_amount = netAmount.toFixed(3);
    newInitiatedPayment.tax_value = taxValue;
    return newInitiatedPayment;
}
export const updateRequestIdUtils = (id, initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    newInitiatedPayment.request_id = id;
    // return newInitiatedPayment;
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

export const updateCommentUuidUtils = (uuid, initiatedPayment, currenciesRate) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    newInitiatedPayment.comments_uuid = uuid;
    return updateNewPaymentCurrency(newInitiatedPayment, currenciesRate);
}

const updateNewPaymentCurrency = (initiatedPayment, currenciesRate) => {
    let newCurrenciesRate = {};
    let currenciesRateOrg = Object.assign({}, currenciesRate);
    Object.keys(currenciesRateOrg).map(currency => {
        if(initiatedPayment.customCurrenciesRate[currency])  currenciesRateOrg[currency] = initiatedPayment.customCurrenciesRate[currency];
            newCurrenciesRate[currency] = currenciesRateOrg[currency];
        return currency;
    })

    fx.base = "EUR";
    fx.rates = newCurrenciesRate;

    const projectCurrency = initiatedPayment.project_currency_name;
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    let total = 0;
    newInitiatedPayment.paymentItems.map(payment => {
      let paymentCurrency = payment.currency_name;
      if(payment.payment_value) {
        payment.total = fx(payment.payment_value).convert({ from:paymentCurrency, to:projectCurrency }).toFixed(3);
        let rate = parseFloat(initiatedPayment.customCurrenciesRate[paymentCurrency] ? initiatedPayment.customCurrenciesRate[paymentCurrency] : currenciesRate[paymentCurrency])
        payment.rate = rate.toFixed(3);
        if(paymentCurrency === 'EUR') {
            payment.rate = fx(1).convert({ from:paymentCurrency, to:projectCurrency }).toFixed(3);
        }
        if(paymentCurrency === projectCurrency) {
            payment.rate = 1;
        }

        total = total + parseFloat(payment.total);
        if(!initiatedPayment.customCurrenciesRate[paymentCurrency]) {
            initiatedPayment.customCurrenciesRate[paymentCurrency] = rate; 
        }
      }
      return payment;
    });
    newInitiatedPayment.total_value = total;
    return newInitiatedPayment;
  }


  export const addCommentUtils = (initiatedPayment, comment) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    newInitiatedPayment.comments.push({
        user_id: comment.user_id,
        body: comment.body,
        created_at: moment(new Date()).format('LLLL')
    }) 
    return newInitiatedPayment;
  }

  export const deleteCommentUtils = (initiatedPayment, comment) => {
    let newInitiatedPayment = Object.assign({}, initiatedPayment);
    if(comment.id !== undefined) {
        newInitiatedPayment.comments[comment.index].onDelete = true;
    } else {
        newInitiatedPayment.comments.splice(comment.index, 1);
    }
    return newInitiatedPayment;
  }