import axios from "axios";
import { API } from "../../config/api";
import { createLogic } from "redux-logic";
import { UserActionTypes } from './user.types';

export const updateUserProfileLogic = createLogic({
    // declarative built-in functionality wraps your code
    type: UserActionTypes.UPDATE_CURRENT_USER, // only apply this logic to this type
    cancelType: UserActionTypes.UPDATE_CURRENT_USER, // cancel on this type
    latest: true, // only take latest
    process({ getState, action }, dispatch, done) {
      axios.get(API.CMS + '/users/me')
      .then(resp => resp.data)
      .then(async user => {
        dispatch({ type: UserActionTypes.SET_CURRENT_USER, payload: user })
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); // call done when finished dispatching
    }
  });