const DOMAIN = "https://intranetapi.leaders.ps"
const API_URL = `${DOMAIN}/api/1.0`;
const API_URL_INTRANET = API_URL + "/intranet";
const API_URL_PUBLIC = API_URL + "/public";
const PROC_PLAN_ID = 1;
const TASK_FILTER = 'tasks?page=1&include=process,processRequest,processRequest.user,user,data,process.user&per_page=20&filter=&statusfilter=ACTIVE&order_by=ID&order_direction=DESC';

export const API = {
  DOMAIN:                    "https://intranetapi.leaders.ps",
  URL:                       API_URL,
  INTRANET:                  API_URL_INTRANET,
  PROC_TYPE:                 `${API_URL_INTRANET}/proctypes`,
  PROJECTS:                  `${API_URL_INTRANET}/getprojects`,
  ENTITIES:                  `${API_URL_INTRANET}/entities`,
  PROC_METHOD:               `${API_URL_INTRANET}/procmethods`,
  PROCUREMENT_PLAN:          `${API_URL}/process_events/4?event=task_start`,
  PROCUREMENT_ACTIVITY:      `${API_URL}/process_events/4?event=task_start_pa`,
  CONTRACTING_START:         `${API_URL}/process_events/4?event=task_start_contract`,
  PROC_PLAN_PROCESS_BY_USER: `${API_URL_INTRANET}/user_processes/${PROC_PLAN_ID}`,
  PROC_ACTIVITIES:           `${API_URL_INTRANET}/procurement_activities`,
  BL_OWNER_TASKS:            `${API_URL_INTRANET}/bl-owner-tasks`,
  CONTRACTING_TASKS:         `${API_URL_INTRANET}/contracting-tasks`,
  PAYMENT_TASKS:             `${API_URL_INTRANET}/payment-tasks`,
  PAYMENT_REQUEST:           `${API_URL}/process_events/3?event=start_payment_process`,
  //PUBLIC
  GET_CURRENCY_RATE:          `${API_URL_PUBLIC}/get-currency-exchange`,
  LOGIN:                      `${API_URL}/login`,
  //OTHER API
  COUNTRIES:                  'https://restcountries.eu/rest/v2/all',
  // Functional URLs
  USER_TASKS:                (val) => { return `${API_URL}/${TASK_FILTER}&user_id=${val}`},
  PROCESS_TASK:              (val) => { return `${API_URL}/tasks?process_request_id=${val}`},
  TASK_URL:                  (val) => { return `${API_URL}/tasks/${val}`},
  PROJECT:                   (val) => { return `${API_URL_INTRANET}/project/${val}`},
  PROJECT_ACTIVITIES:        (val) => { return `${API_URL_INTRANET}/project-activities/${val}`},
  PROJ_BUDGETLINES:          (val) => { return `${API_URL_INTRANET}/project/${val}/budgetlines`},
  GROUP_USERS_BY_ID:         (val) => { return `${API_URL}/groups/${val}/users`},
  PROC_UPDATE_STATUS:        (val) => { return `${API_URL_INTRANET}/project/procurement/${val}/update-status`},
  PROC_RESP_ROUTE:           (val) => { return `${API_URL_INTRANET}/project/procurement/${val}/proc-resp-route`},
  PROC_REQ_ITEMS:            (val) => { return `${API_URL_INTRANET}/procurement_activity/${val}/items`},
  PROC_REQ_CRITERIA:         (val) => { return `${API_URL_INTRANET}/procurement_activity/${val}/criteria`},
  PROC_ROUTE_EVALUATION:     (val) => { return `${API_URL_INTRANET}/procurement_activity/${val}/route-evaluation`},
  PROC_ROUTE_EVALUATION_APPROVAL: (val) => { return `${API_URL_INTRANET}/procurement_activity/${val}/route-evaluation-approval`},
  PROC_RESPONDERS_EVALUATION:(val) => { return `${API_URL_INTRANET}/procurement_activity/${val}/get-evaluation`},
  PROC_ACTIVITY_BY_ID:       (val) => { return `${API_URL_INTRANET}/procurement_activities/all?ids=${val}`},
  PROC_ACTIVITY:             (val) => { return `${API_URL_INTRANET}/procurement_activities/${val}`},
  PROCESS_FILES:             (val) => { return `${API_URL}/requests/${val}/files`},
  DEL_PROCESS_FILE:          (val) => { return `${API_URL}/requests/${val.requestId}/files/${val.fileId}`},
  TASK:                      (val) => { return `${API_URL}/${TASK_FILTER}&process_request_id=${val}`},
  TASK_ID:                   (val) => { return `${API_URL}/tasks/${val}`},
  REQUEST:                   (val) => { return `${API_URL}/requests/${val}`},
  REQUEST_FILES:             (val) => { return `${API_URL}/requests/${val}/files`},
  FILE:                      (val) => { return `${API_URL}/files/${val}`},
  REQUEST_COMMENTS:          (val) => { return `${API_URL_INTRANET}/payment-comments/${val}`},
  GET_INTRANET_TYPE:         (val) => { return `${API_URL_INTRANET}/get-intranet-type/${val}`},
  CONTRACTING_TASK:          (procId, responderId) => { return `${API_URL_INTRANET}/contracting-tasks/${procId}/${responderId}`},
  CONTRACTING_TASK_NEW_ENTITY: (procId, responderId) => { return `${API_URL_INTRANET}/contracting-tasks/${procId}/${responderId}/new-entity`}, 
  CONTRACTING_TASK_SELECTED_ENTITY: (procId, responderId) => { return `${API_URL_INTRANET}/contracting-tasks/${procId}/${responderId}/selected-entity`},
  CONTRACT_SAVE:             (procId, responderId) => { return `${API_URL_INTRANET}/contracting-tasks/${procId}/${responderId}/save-contract`},
  CONTRACT_ID:               (val) => { return `${API_URL_INTRANET}/contracting-task/${val}`} ,
  PAYMENT_TASK_DETAILS:      (val) => { return `${API_URL_INTRANET}/payment-task/${val.contractId}/${val.paymentId}`} ,
  PAYMENT_REQUEST_INFO:      (val) => { return `${API_URL_INTRANET}/payment-request/${val}`} ,
  INITIATE_PAYMENT_REQUEST:  (val) => { return `${API_URL_INTRANET}/payment-task/${val.contractId}/${val.paymentId}/initiate-payment-request`} ,
  UPDATE_PAYMENT_REQUEST:    (val) => { return `${API_URL_INTRANET}/payment-task/update-payment-request/${val}`} ,
  GET_ENTITY:                (val) => { return `${API_URL_INTRANET}/get-entity/${val}`} ,
  GET_COMMENTS:              (val) => { return `${API_URL_INTRANET}/payment-task/comments/${val}`} ,
  GET_BUDGET_LINE_OWNER:     (val) => { return `${API_URL_INTRANET}/budget-lines/${val}`} ,
};

