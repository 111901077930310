export const logo = ['608 134', `
<title>Leaders Intranet</title>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 343.78 114.66" style="enable-background:new 0 0 343.78 114.66;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FFFFFF;}
.st1{fill:#737B82;}
</style>
<polygon class="st0" points="146.95,92.47 123.59,92.47 123.59,65.39 128.31,65.39 128.31,87.75 146.95,87.75 "/>
<path class="st0" d="M173.88,92.47h-23.4V65.39h23.4v4.72H155.2v17.65h18.68V92.47z M172.66,81.09h-15.48v-4.72h15.48V81.09z"/>
<path class="st0" d="M213.94,92.47l-6.06-0.04l-4.57-7.57h-12.85l2.86-4.68h7.18l-4.72-7.81l-12.22,20.07h-6.07l14.72-24.47
c0.37-0.63,0.88-1.21,1.54-1.75c0.8-0.6,1.51-0.91,2.15-0.91c0.68,0,1.39,0.29,2.14,0.88c0.64,0.5,1.15,1.09,1.55,1.78L213.94,92.47
z"/>
<path class="st0" d="M246.15,78.63c0,3.99-1.29,7.3-3.85,9.92c-2.56,2.62-5.83,3.92-9.8,3.92h-15.27V65.39h15.27
c4,0,7.27,1.21,9.82,3.65C244.87,71.47,246.15,74.67,246.15,78.63 M241.43,79.03c0-2.62-0.84-4.76-2.52-6.43
c-1.68-1.66-3.82-2.5-6.41-2.5h-10.58v17.65h10.58c2.6,0,4.73-0.81,6.41-2.42C240.59,83.72,241.43,81.62,241.43,79.03"/>
<path class="st0" d="M273.39,92.47h-23.4V65.39h23.4v4.72H254.7v17.65h18.69V92.47z M272.16,81.09h-15.47v-4.72h15.47V81.09z"/>
<path class="st0" d="M310.39,92.47h-6.97l-7.58-7.61h-10.19v-4.68h12.69c1.74,0,3.21-0.38,4.41-1.15c1.34-0.9,2.02-2.18,2.02-3.85
c0-3.38-2.14-5.08-6.43-5.08h-15.47v22.37h-4.71V65.39h19.43c3.49,0,6.31,0.75,8.44,2.26c2.46,1.72,3.69,4.24,3.69,7.57
c0,2.27-0.71,4.23-2.14,5.87c-1.33,1.54-3.12,2.6-5.4,3.22L310.39,92.47z"/>
<path class="st0" d="M343.75,84.11c0,2.57-0.91,4.63-2.73,6.18c-1.76,1.46-3.94,2.18-6.55,2.18H313.8v-4.72h20.67
c1.29,0,2.42-0.29,3.37-0.88c1.08-0.71,1.63-1.68,1.63-2.89c0-1.21-0.56-2.15-1.67-2.81c-0.93-0.55-2.04-0.84-3.33-0.84H321.7
c-2.36,0-4.32-0.64-5.91-1.94c-1.7-1.4-2.54-3.25-2.54-5.55c0-2.27,0.84-4.11,2.54-5.51c1.59-1.29,3.55-1.94,5.91-1.94h22.08v4.72
H321.7c-0.9,0-1.67,0.26-2.3,0.75c-0.63,0.51-0.95,1.18-0.95,2.02c0,0.87,0.32,1.55,0.95,2.02c0.64,0.47,1.4,0.71,2.3,0.71h12.77
c2.61,0,4.79,0.76,6.55,2.27C342.84,79.47,343.75,81.54,343.75,84.11"/>
<polygon class="st0" points="169.56,0 173.99,4.52 220.84,49.35 196.58,49.35 170.66,21.6 155.86,32.77 169.69,49.35 149.64,49.35 
140.69,39.74 127.65,49.35 101.47,49.35 "/>
<polygon class="st0" points="51.28,114.66 46.86,110.14 0,65.3 24.27,65.3 50.19,93.06 64.98,81.88 51.15,65.3 71.2,65.3 
80.16,74.92 93.2,65.3 119.38,65.3 "/>
<rect x="0" y="55.76" class="st0" width="343.74" height="3.6"/>
<rect x="123.59" y="98.82" class="st0" width="1.56" height="10.12"/>
<polygon class="st0" points="144.76,108.94 143.16,108.94 136.97,100.95 136.97,108.94 135.5,108.94 135.5,98.82 137.1,98.82 
143.28,106.81 143.28,98.82 144.76,98.82 "/>
<polygon class="st0" points="163.32,100.02 159.41,100.02 159.41,108.94 157.85,108.94 157.85,100.02 153.97,100.02 153.97,98.82 
163.32,98.82 "/>
<polygon class="st0" points="181.33,108.94 172.52,108.94 172.52,98.82 181.06,98.82 181.06,100.02 174.08,100.02 174.08,103.09 
174.08,103.09 174.08,104.3 174.08,104.3 174.08,107.74 181.33,107.74 "/>
<polygon class="st0" points="219.74,108.94 218.15,108.94 211.95,100.95 211.95,108.94 210.48,108.94 210.48,98.82 212.09,98.82 
218.26,106.81 218.26,98.82 219.74,98.82 "/>
<polygon class="st0" points="256.83,100.02 252.92,100.02 252.92,108.94 251.36,108.94 251.36,100.02 247.49,100.02 247.49,98.82 
256.83,98.82 "/>
<rect x="266.24" y="98.82" class="st0" width="1.56" height="10.12"/>
<path class="st0" d="M283.26,98.64c0.81,0,1.56,0.13,2.26,0.38c0.69,0.25,1.28,0.61,1.78,1.06c0.5,0.46,0.89,1.01,1.17,1.65
c0.28,0.64,0.42,1.36,0.42,2.14c0,0.77-0.14,1.48-0.41,2.12c-0.27,0.64-0.66,1.19-1.17,1.66c-0.5,0.46-1.1,0.82-1.78,1.08
c-0.69,0.25-1.44,0.38-2.27,0.38c-0.83,0-1.59-0.13-2.28-0.38c-0.69-0.25-1.29-0.61-1.78-1.07c-0.5-0.46-0.89-1.01-1.17-1.66
c-0.28-0.65-0.42-1.36-0.42-2.14c0-0.79,0.14-1.51,0.42-2.15c0.28-0.65,0.67-1.2,1.18-1.65c0.51-0.46,1.1-0.81,1.78-1.05
C281.68,98.76,282.44,98.64,283.26,98.64 M283.24,107.97c0.59,0,1.13-0.09,1.62-0.28c0.5-0.19,0.92-0.46,1.28-0.81
c0.36-0.35,0.64-0.78,0.84-1.29c0.2-0.51,0.3-1.08,0.3-1.72c0-0.65-0.1-1.23-0.31-1.73c-0.2-0.5-0.48-0.93-0.84-1.28
c-0.36-0.35-0.78-0.61-1.27-0.79s-1.02-0.27-1.6-0.27c-0.58,0-1.12,0.09-1.62,0.28c-0.5,0.18-0.93,0.45-1.29,0.8
c-0.36,0.35-0.64,0.77-0.84,1.28c-0.2,0.5-0.3,1.07-0.3,1.72c0,0.64,0.1,1.21,0.3,1.72c0.2,0.51,0.48,0.93,0.83,1.29
c0.36,0.35,0.78,0.62,1.27,0.81C282.12,107.87,282.66,107.97,283.24,107.97"/>
<polygon class="st0" points="307.77,108.94 306.18,108.94 299.98,100.95 299.98,108.94 298.51,108.94 298.51,98.82 300.11,98.82 
306.29,106.81 306.29,98.82 307.77,98.82 "/>
<polygon class="st1" points="343.78,108.94 336.47,108.94 336.47,98.82 338.03,98.82 338.03,107.74 343.78,107.74 "/>
<polyline class="st0" points="239.6,108.94 239.6,108.94 239.6,108.94 "/>
<path class="st0" d="M234.8,99.2c-0.29-0.59-1.44-0.55-1.69,0c-4.69,10.01-4.52,9.73-4.52,9.73h1.64l0.77-1.81l1.21-2.95h0.01
l1.73-4.08l2.08,4.69h-3.1l-0.44,1.07h3.99l1.36,3.08h1.77C239.59,108.9,239.28,108.28,234.8,99.2"/>
<rect x="175.07" y="103.09" class="st0" width="5.98" height="1.21"/>
<polyline class="st1" points="327.52,108.94 327.52,108.94 327.52,108.94 "/>
<path class="st0" d="M322.72,99.2c-0.29-0.59-1.44-0.55-1.69,0l-4.52,9.73h1.64l0.77-1.81l1.21-2.95h0.01l1.73-4.08l2.08,4.69h-3.1
l-0.44,1.07h3.99l1.36,3.08h1.77C327.51,108.9,327.52,108.94,322.72,99.2"/>
<path class="st0" d="M197.78,104.41c0.41-0.08,0.82-0.27,1.15-0.42c0.33-0.16,0.61-0.35,0.83-0.58c0.23-0.23,0.4-0.5,0.52-0.8
c0.12-0.3,0.18-0.62,0.18-0.97c0-0.41-0.09-0.8-0.27-1.19s-0.45-0.71-0.81-0.97c-0.21-0.15-0.43-0.27-0.66-0.36
c-0.23-0.09-0.48-0.15-0.73-0.2c-0.25-0.05-0.53-0.07-0.82-0.08s-0.59-0.02-0.91-0.02h-5.22v10.12h1.56v-2.54v-1.78v-1.21v-1.22
v-2.25h3.73c1.69,0,2.53,0.57,2.53,1.71c0,0.65-0.22,1.11-0.65,1.37c-0.44,0.26-1.14,0.39-2.11,0.39h-2.41v1.21h1.8
C195.5,104.61,197.11,104.54,197.78,104.41"/>
<polygon class="st0" points="196.9,104.32 195.34,104.32 198.69,108.94 200.25,108.94 "/>
</svg>

`]
