import { API } from "../../config/api";
import { createLogic } from "redux-logic";
import ContractingTypes from "./contracting.types";
import ProcurementTypes from "../procurement/procurement.types";
import IntranetTypes from "../intranet/intranet.types";

export const getContractingActivitiesLogic = createLogic({
    type: ContractingTypes.FETCH_CONTRACTING_ACTIVITIES,
    latest: true,
    process({ httpClient, getState, action }, dispatch, done) {
      httpClient.get(API.CONTRACTING_TASKS)
      .then(resp => resp.data)
      .then(async activities => {
        dispatch({ type: ContractingTypes.SET_CONTRACTING_ACTIVITIES, payload: activities})
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
  });
  
  export const getContractingActivityLogic = createLogic({
      type: ContractingTypes.FETCH_CONTRACTING_ACTIVITY,
      latest: true,
      process({ httpClient, getState, action }, dispatch, done) {
        httpClient.get(API.CONTRACTING_TASK(action.payload.procId, action.payload.responderId))
        .then(resp => resp.data)
        .then(async activity => {
          if(activity.project_activity) {
            dispatch({ type: IntranetTypes.SET_PROJECT_BY_ID, payload: activity.project_activity.project_id})
            dispatch({ type: ProcurementTypes.ADD_TO_PROCUREMENTS, payload: [activity]})
          };
          dispatch({ type: ContractingTypes.SET_CONTRACTING_ACTIVITY, payload: activity})
        })
        .catch(err => {
            console.error(err);
        }).then(() => done()); 
      }
    });