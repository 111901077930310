export const updateProcurementFieldFromPayload = (procurements, procurement) => {
    console.log(procurement)
    let name = procurement[0], value = procurement[1], index = procurement[2], newArray = [...procurements];
    newArray[index] = { ...newArray[index], [name]: value }
    return newArray;
};

export const removeProcurementByIndex = (procurements, index) => {
    let newArray = [...procurements];
    newArray.splice(index, 1);
    return newArray;
}

export const updateItemFieldUtil = (items, item) => {
    let name = item[0], value = item[1], index = item[2], newArray = [...items];
    newArray[index] = { ...newArray[index], [name]: value }
    return newArray;
};

export const removeItemByIndexUtil = (items, index) => {
    let newArray = [...items];
    newArray.splice(index, 1);
    return newArray;
}

export const addFileToItemUtil = (items, fileArray) => {
    let index = fileArray.itemId, file = fileArray.response;
    let newArray = [...items];
    newArray[index] = { ...newArray[index], file: file }
    return newArray;
}

export const removeFileFromItemUtil = (items, index) => {
    let newArray = [...items];
    newArray[index] = { ...newArray[index], file: {} }
    return newArray;
}

////////// EVALUATION //////////

export const removeEvaluationByIndexUtil = (evaluation, index) => {
    let newArray = [...evaluation];
    newArray.splice(index, 1);
    return newArray;
}

export const updateEvaluationFieldUtil = (evaluations, evaluation) => {
    let name = evaluation[0], value = evaluation[1], index = evaluation[2], newArray = [...evaluations];
    newArray[index] = { ...newArray[index], [name]: value }
    return newArray;
};

export const addSubCriteriaUtil = (criteria, index) => { 
    let newArray = [...criteria];
    let sub = [...newArray[index].subCriteria];
    sub.push({
        label: '',
        weight: ''
    })
    newArray[index] = { ...newArray[index], subCriteria: sub }
    return newArray
}


export const updateProcurementStateFieldUtil = (state, target) => {
    let name = target.field[0], value = target.field[1], index = target.field[2], newArray = [...state];
    newArray[index] = { ...newArray[index], [name]: value }
    return newArray;
}

export const updateProcurementStateSubFieldUtil = (state, target) => {
    
    let 
        field = target.field[0],
        name = target.field[1],
        value = target.field[2],
        index = target.field[3],
        subIndex = target.field[4],
        newArray = [...state];

    let subArray = newArray[index][field];
        subArray[subIndex] = { ...subArray[subIndex], [name]: value }

    return newArray
}


////settings////
export const changeProcurementViewUtil = (settings, view) => {
    let show = !settings.detailedView;
    if(view) show = view;
    settings.detailedView = show;
    return settings;
}