const IntranetTypes = {
    ADD_CONTENT: 'ADD_CONTENT',
    SET_PROJECT: 'SET_PROJECT',
    SET_PROJECT_BY_ID: 'SET_PROJECT_BY_ID',
    GET_PROJECT_ACTIVITIES: 'GET_PROJECT_ACTIVITIES',
    SET_PROJECT_ACTIVITIES: 'SET_PROJECT_ACTIVITIES',
    USERS_FETCH: "USERS_FETCH",
    USERS_FETCH_CANCEL: "USERS_FETCH_CANCEL", 
    USERS_FETCH_REJECTED: "USERS_FETCH_REJECTED",
    GET_COUNTRIES: "GET_COUNTRIES",
    SET_COUNTRIES: "SET_COUNTRIES",
    GET_INTRANET_BY_TYPE: "GET_INTRANET_BY_TYPE",
    SET_INTRANET_BY_TYPE: "SET_INTRANET_BY_TYPE",
    GET_CURRENCY_RATES: "GET_CURRENCY_RATES",
    SET_CURRENCY_RATES: "SET_CURRENCY_RATES",
    DOWNLOAD_FILE: "DOWNLOAD_FILE",
}

export default IntranetTypes;