import ProcurementTypes from './procurement.types';
import {
  removeEvaluationByIndexUtil,
  updateEvaluationFieldUtil,
  addFileToItemUtil,
  removeFileFromItemUtil,
  removeItemByIndexUtil,
  removeProcurementByIndex,
  updateItemFieldUtil,
  updateProcurementFieldFromPayload,
  addSubCriteriaUtil,
  updateProcurementStateFieldUtil,
  updateProcurementStateSubFieldUtil,
  changeProcurementViewUtil
} from './procurement.utils';

const PROC_FIELDS = {
  proc_activity_title: '',
  project_activity_id: '',
  budgetline_id: '',
  bl_owner_id: '',
  proc_type_id: '',
  proc_method_id: '',
  num_contracts: '',
  value_contracts: '',
  est_contract_value: '',
  proc_start: '',
  proc_end: '',
  entity_id: '',
  assigned_user_id: '',
  rsp_user_id: '',
  notes: ''
};

const ITEM_FIELDS = {
  item: '',
  quantity: '',
  specs: '',
  file: {},
}


const EVALUATION = {
  label: '',
  weight: '',
  subCriteria: []
}

const CRITERIA = {
  label: '',
  weight: '',
  subCriteria: []
}
const SETTINGS = {
  detailedView: false,
  hasApproval: false,
  isNew: false
}
const INITIAL_STATE = {
  list: [],
  items: [ITEM_FIELDS],
  criteria: [CRITERIA],
  new: PROC_FIELDS,
  settings: SETTINGS
};

const LIST = {
  items: ITEM_FIELDS,
  criteria: CRITERIA
}
const procurementsReducer = (state = INITIAL_STATE, action) => {
  let newList = {};
  switch (action.type) {

    //SETTINGS /////////
    case ProcurementTypes.CHANGE_VIEW:
      return {
        ...state,
        settings: changeProcurementViewUtil(state.settings)
      }
    case ProcurementTypes.SET_IS_NEW:
      const isNew = action.payload;
      state.settings.isNew = isNew;
      return {
        ...state,
        settings: state.settings
      }

      ///
    case ProcurementTypes.INIT_PROCUREMENT:
      return {
        ...state,
        list: [PROC_FIELDS]
      }

    case ProcurementTypes.ADD_PROCUREMENT:
      newList = Object.assign({}, PROC_FIELDS);
      return {
        ...state,
        list: [...state.list, newList]
      }

    case ProcurementTypes.ADD_TO_PROCUREMENTS:
      return {
        ...state,
        list: action.payload
      }
    case ProcurementTypes.UPDATE_FIELD:
      return {
        ...state,
        list: updateProcurementFieldFromPayload(state.list, action.payload)
      }
    case ProcurementTypes.CLEAR:
      return {
        ...state,
        list: []
      }
    case ProcurementTypes.REMOVE_PROCUREMENT:
      return {
        ...state,
        list: removeProcurementByIndex(state.list, action.payload)
      }
    
    ////////////// ITEMS ////////////

    case ProcurementTypes.INIT_ITEMS:
      return {
        ...state,
        items: [ITEM_FIELDS]
      }

    case ProcurementTypes.ADD_ITEMS:
      newList = Object.assign({}, ITEM_FIELDS);
      return {
        ...state,
        items: [...state.items, newList]
      }

    case ProcurementTypes.ADD_TO_ITEMS:
      return {
        ...state,
        items: action.payload
      }

    case ProcurementTypes.REMOVE_ITEM:
      return {
        ...state,
        items: removeItemByIndexUtil(state.items, action.payload)
      }
    case ProcurementTypes.UPDATE_ITEMS_FIELD:
      return {
        ...state,
        items: updateItemFieldUtil(state.items, action.payload)
      }

    case ProcurementTypes.SET_ITEM_ON_UPLOAD:
      return {
        ...state
      }
    case ProcurementTypes.ADD_FILE_TO_ITEM:
      return {
        ...state,
        items: addFileToItemUtil(state.items, action.payload)
      }
    case ProcurementTypes.REMOVE_FILE_FROM_ITEM:
      return {
        ...state,
        items: removeFileFromItemUtil(state.items, action.payload)
      }

    ////////////// EVALUATION ////////////

    case ProcurementTypes.INIT_EVALUATION:
      return {
        ...state,
        evaluation: [EVALUATION]
      }

    case ProcurementTypes.ADD_EVALUATION:
      newList = Object.assign({}, EVALUATION);
      return {
        ...state,
        evaluation: [...state.evaluation, newList]
      }

    case ProcurementTypes.ADD_TO_EVALUATION:
      return {
        ...state,
        evaluation: action.payload
      }

    case ProcurementTypes.REMOVE_EVALUATION:
      return {
        ...state,
        evaluation: removeEvaluationByIndexUtil(state.evaluation, action.payload)
      }
    case ProcurementTypes.UPDATE_EVALUATION_FIELD:
      return {
        ...state,
        evaluation: updateEvaluationFieldUtil(state.evaluation, action.payload)
      }



    ////////////// STATE ////////////

    case ProcurementTypes.INIT_STATE:
      return {
        ...state,
        [action.payload.target]: [action.payload.list]
      }

    case ProcurementTypes.ADD_STATE:
      newList = Object.assign({}, LIST[action.payload.list]);
      return {
        ...state,
        [action.payload.target]: [...state[action.payload.target], newList]
      }

    case ProcurementTypes.ADD_TO_STATE:
      return {
        ...state,
        [action.payload.target]: action.payload.list
      }

    case ProcurementTypes.ADD_SUB_CRITERIA:
      return {
        ...state,
        criteria: addSubCriteriaUtil(state.criteria, action.payload)
      }

    case ProcurementTypes.UPDATE_STATE_FIELD:
      return {
        ...state,
        [action.payload.target]: updateProcurementStateFieldUtil(state[action.payload.target], action.payload)
      }

    case ProcurementTypes.UPDATE_STATE_SUB_FIELD:
      return {
        ...state,
        [action.payload.target]: updateProcurementStateSubFieldUtil(state[action.payload.target], action.payload)
      }



    default:
      return state;
  }
}

export default procurementsReducer;