import IntranetTypes from './intranet.types';
import { setCurrentProjectByIdUtils, setCurrentProjectUtils } from './intranet.utils';

const INITIAL_STATE = {
  projects: {},
  entities: [],
  methods: [],
  types: [],
  users: [],
  currentProject: [],
  countries:[],
  currencies: [],
  currenciesRate: []
};


const intranetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IntranetTypes.ADD_CONTENT:
      return {
        ...state,
        projects: action.payload.li_projects,
        entities: action.payload.entities,
        methods: action.payload.methods,
        types: action.payload.types,
        users: action.payload.users,
        currencies: action.payload.currencies
      }

    case IntranetTypes.SET_PROJECT:
      return {
        ...state,
        currentProject: setCurrentProjectUtils(state.projects, action.payload)
      }

    case IntranetTypes.SET_PROJECT_BY_ID:
      return {
        ...state,
        currentProject: setCurrentProjectByIdUtils(state.projects, action.payload)
      }

    case IntranetTypes.SET_PROJECT_ACTIVITIES:
      let activity = action.payload;
      let newArray = state.currentProject;
      newArray.activity = activity
      return {
        ...state,
        currentProject: newArray
      }
    case IntranetTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      }
    case IntranetTypes.SET_INTRANET_BY_TYPE:
      return {
        ...state,
        [action.payload.target]: action.payload.data
      }
    case IntranetTypes.SET_CURRENCY_RATES:
      return {
        ...state,
        currenciesRate: action.payload
      }
    default:
      return state;
  }
}

export default intranetReducer;