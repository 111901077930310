const ProcurementTypes = {

    INIT_PROCUREMENT: 'INIT_PROCUREMENT',
    ADD_PROCUREMENT: 'ADD_PROCUREMENT',
    ADD_TO_PROCUREMENTS: 'ADD_TO_PROCUREMENTS',
    UPDATE_FIELD: 'UPDATE_FIELD',
    CLEAR: 'CLEAR',
    REMOVE_PROCUREMENT: 'REMOVE_PROCUREMENT',

    GET_BUDGET_LINE_OWNER: 'GET_BUDGET_LINE_OWNER',

    ///////
    INIT_ITEMS: 'INIT_ITEMS',
    ADD_ITEMS: 'ADD_ITEMS',
    ADD_TO_ITEMS: 'ADD_TO_ITEMS',
    ADD_FILE_TO_ITEM: 'ADD_FILE_TO_ITEM',
    REMOVE_FILE_FROM_ITEM: 'REMOVE_FILE_FROM_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    UPDATE_ITEMS_FIELD: 'UPDATE_ITEMS_FIELD',
    SET_ITEM_ON_UPLOAD: 'SET_ITEM_ON_UPLOAD',
    SET_ITEM_UPLOAD_DONE: 'SET_ITEM_UPLOAD_DONE',

    ///////
    INIT_CRITERIA: 'INIT_CRITERIA',
    ADD_CRITERIA: 'ADD_CRITERIA',
    ADD_TO_CRITERIA: 'ADD_TO_CRITERIA',
    REMOVE_CRITERIA: 'REMOVE_CRITERIA',
    UPDATE_CRITERIA_FIELD: 'UPDATE_CRITERIA_FIELD',

    ///////
    INIT_EVALUATION: 'INIT_EVALUATION',
    ADD_EVALUATION: 'ADD_EVALUATION',
    ADD_TO_EVALUATION: 'ADD_TO_EVALUATION',
    REMOVE_EVALUATION: 'REMOVE_EVALUATION',
    UPDATE_EVALUATION_FIELD: 'UPDATE_EVALUATION_FIELD',

    ///////
    INIT_STATE: 'INIT_STATE',
    ADD_STATE: 'ADD_STATE',
    ADD_TO_STATE: 'ADD_TO_STATE',
    UPDATE_STATE_FIELD: 'UPDATE_STATE_FIELD',
    UPDATE_STATE_SUB_FIELD: 'UPDATE_STATE_SUB_FIELD',

    ADD_SUB_CRITERIA: 'ADD_SUB_CRITERIA',
    FETCH_CRITERIA: 'FETCH_CRITERIA',

    ////////settings
    CHANGE_VIEW: 'CHANGE_VIEW',
    HAS_APPROVAL: 'HAS_APPROVAL',
    SET_IS_NEW: 'SET_IS_NEW'

}

export default ProcurementTypes;