import PaymentTypes from './payment.types'
import { addCommentUtils, addPaymentItemUtils, deleteCommentUtils, refreshInitiatedPaymentUtils, setPaymentEndsOnUtils, removePaymentItemUtils, restoreCurrencyRateUtils, setCommentsUtils, setPaymentFilesUtils, updateCommentUuidUtils, updateCustomCurrenciesUtils, updateInitiatedPaymentItemsUtils, updateInitiatedPaymentRequesterUtils, updateInitiatedPaymentUtils, updateRequestIdUtils, calculateTaxUtils } from './payment.utils'

const INITIAL_STATE = {
    list: [],
    payment: {},
    currenciesRate: [],
    editCustomCurrencyRate: '',
    commentValue: '',
    entityInfo: {},
    financeOfficers: [],
    initiatedPayment: {
        customCurrenciesRate: {},
        payment_request_number: '',
        request_id: '',
        contract_id: '',
        payment_id: '',
        requester_id: '',
        requester_title: '',
        fullname: '',
        order_date: '',
        currency_name: '',
        project_id: '',
        project_currency_name:'',
        entity_id: '',
        total_value: '',
        paymentItems: [],
        documents: [],
        comments: [],
      }
}

const paymentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PaymentTypes.ADD_TO_PAYMENTS:
            return {
                ...state,
                list: action.payload
            }
        case PaymentTypes.INITIATED_PAYMENT:
            return {
                ...state,
                initiatedPayment: action.payload
            }
        case PaymentTypes.UNMOUNT_COMPONENT:
            return {
                list: [],
                payment: {},
                currenciesRate: [],
                editCustomCurrencyRate: '',
                commentValue: '',
                initiatedPayment: {
                    customCurrenciesRate: {},
                    payment_request_number: '',
                    request_id: '',
                    contract_id: '',
                    payment_id: '',
                    requester_id: '',
                    requester_title: '',
                    fullname: '',
                    order_date: '',
                    currency_name: '',
                    project_id: '',
                    project_currency_name:'',
                    entity_id: '',
                    total_value: '',
                    paymentItems: [],
                    documents: [],
                    comments: [],
                    tax_rate: '',
                    tax_type: '',
                    tax_value: '',
                    net_amount: ''
                  }
            }
        case PaymentTypes.ADD_TO_PAYMENT:
            return {
                ...state,
                list: [],
                payment: action.payload
            }
        case PaymentTypes.UPDATE_INITIATED_PAYMENT:
            return {
                ...state, 
                initiatedPayment: updateInitiatedPaymentUtils(state.initiatedPayment , state.payment)
            }
        case PaymentTypes.REFRESH_INITIATED_PAYMENT:
            return {
                ...state, 
                initiatedPayment: refreshInitiatedPaymentUtils(state.initiatedPayment , state.currenciesRate)
            }
        case PaymentTypes.SET_ENTITY_INFO:
            return {
                ...state,
                entityInfo: action.payload
            }
        case PaymentTypes.SET_FINANCE_OFFICERS:
            return {
                ...state,
                financeOfficers: action.payload.data
            }
        case PaymentTypes.SET_COMMENTS:
            return {
                ...state,
                initiatedPayment: setCommentsUtils(action.payload, state.initiatedPayment , state.currenciesRate)
            }
        case PaymentTypes.SET_PAYMENT_ENDS_ON:
            return {
                ...state,
                initiatedPayment: setPaymentEndsOnUtils(action.payload, state.initiatedPayment , state.currenciesRate)
            }
        case PaymentTypes.UPDATE_REQUESTER_INFO:
            return {
                ...state,
                initiatedPayment: updateInitiatedPaymentRequesterUtils(state.initiatedPayment, action.payload, state.currenciesRate, state.customCurrenciesRate)
            }
        case PaymentTypes.ON_CHANGE_TAX:
            return {
                ...state,
                initiatedPayment: calculateTaxUtils(state.initiatedPayment, action.payload)
            }
        case PaymentTypes.ON_CHANGE_PAYMENT_ITEM:
            return {
                ...state,
                initiatedPayment: updateInitiatedPaymentItemsUtils(state.initiatedPayment, state.currenciesRate, state.customCurrenciesRate, action.payload),
                editCustomCurrencyRate: ''
            }
        case PaymentTypes.SET_PAYMENT_CURRENCIES:
            return {
                ...state,
                currenciesRate: action.payload
            }  
        case PaymentTypes.SET_CUSTOM_CURRENCIES:
            return {
                ...state,
                initiatedPayment: updateCustomCurrenciesUtils(action.payload, state.initiatedPayment, state.currenciesRate)
            }  
        case PaymentTypes.SET_EDIT_CUSTOM_CURRENCY:
            return {
                ...state,
                initiatedPayment: updateCustomCurrenciesUtils(action.payload, state.initiatedPayment, state.currenciesRate),
                editCustomCurrencyRate: action.payload
            }  
        case PaymentTypes.RESTORE_CURRENCY_RATE:
            return {
                ...state,
                initiatedPayment: restoreCurrencyRateUtils(action.payload, state.initiatedPayment, state.currenciesRate) 
            }  
        case PaymentTypes.ADD_PAYMENT_ITEM:
            return {
                ...state,
                initiatedPayment: addPaymentItemUtils(state.initiatedPayment, state.currenciesRate) 
            }  
        case PaymentTypes.REMOVE_PAYMENT_ITEM:
            return {
                ...state,
                initiatedPayment: removePaymentItemUtils(action.payload, state.initiatedPayment, state.currenciesRate) 
            }  
        case PaymentTypes.UPDATE_REQUEST_ID:
            return {
                ...state,
                initiatedPayment: updateRequestIdUtils(action.payload.id, state.initiatedPayment, state.currenciesRate)
            }  
        case PaymentTypes.UPDATE_COMMENTS_UUID:
            return {
                ...state,
                initiatedPayment: updateCommentUuidUtils(action.payload, state.initiatedPayment, state.currenciesRate)
            }
        case PaymentTypes.SET_PAYMENT_FILES:
            state.initiatedPayment.documents = action.payload.data;
            return {
                ...state,
                initiatedPayment: setPaymentFilesUtils(action.payload, state.initiatedPayment) 
            }
        case PaymentTypes.ON_CHANGE_COMMENT_VALUE:
            return {
                ...state,
                commentValue: action.payload.target.value
            }
        case PaymentTypes.ADD_COMMENT:
            return {
                ...state,
                initiatedPayment: addCommentUtils(state.initiatedPayment, action.payload),
                commentValue: ''
            }
        case PaymentTypes.DELETE_COMMENT:
            return {
                ...state,
                initiatedPayment: deleteCommentUtils(state.initiatedPayment, action.payload) 
            }
        case PaymentTypes.SET_PAYMENT_APPROVAL:
            state.initiatedPayment.approved = action.payload;
            return {
                ...state
            }
        default:
            return state;
        }
}

export default paymentReducer;
