import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import evaluationReducer from './evaluation/evaluation.reducer';
import intranetReducer from './intranet/intranet.reducer';
import notificationReducer from './notification/notification.reducer';
import procurementsReducer from './procurement/procurement.reducer';
import contractingReducer from './contracting/contracting.reducer';
 
import userReducer from './user/user.reducer';
import paymentReducer from './payment/payment.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
}
const persistUserConfig = {
  key: 'user',
  storage
}
const intranetPersistConfig = {
  key: 'intranet',
  storage: storage,
  whitelist: ['countries']
};

const rootReducer = combineReducers({
    user: persistReducer(persistUserConfig, userReducer), 
    intranet: persistReducer(intranetPersistConfig, intranetReducer),
    procurements: procurementsReducer,
    notification: notificationReducer,
    evaluation: evaluationReducer,
    contracts: contractingReducer,
    payments: paymentReducer
},
// cross-cutting concerns because here `state` is the whole state tree
(state, action) => {
  console.log(state)
})

export default persistReducer(persistConfig, rootReducer); 