import IntranetTypes from './intranet.types';

export const setInitiatedContent = content => ({
    type: IntranetTypes.ADD_CONTENT,
    payload: content
});

export const setCurrentProject = project => ({
    type: IntranetTypes.SET_PROJECT,
    payload: project
});

export const setCurrentProjectById = project => ({
    type: IntranetTypes.SET_PROJECT_BY_ID,
    payload: project
});

export const getProjectActivities = () => ({
    type: IntranetTypes.GET_PROJECT_ACTIVITIES
});

export const getCountries = () => ({
    type: IntranetTypes.GET_COUNTRIES
})

export const getIntranetByType = (data) => ({
    type: IntranetTypes.GET_INTRANET_BY_TYPE,
    payload: data
})

export const getCurrencyRates = () => ({
    type: IntranetTypes.GET_CURRENCY_RATES
})


export default setInitiatedContent;