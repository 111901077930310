import { API } from "../../config/api";
import { createLogic } from "redux-logic";
import PaymentTypes from './payment.types';
import IntranetTypes from "../intranet/intranet.types";
import ProcurementTypes from "../procurement/procurement.types";

export const getPaymentsLogic = createLogic({
  type: PaymentTypes.GET_PAYMENTS,
  latest: true,
  async process({ httpClient, getState, action }, dispatch, done) {
    await httpClient.get(action.payload ? API.PAYMENT_TASK_DETAILS(action.payload) : API.PAYMENT_TASKS)
    .then(resp => resp.data)
    .then(async payments => {
      dispatch({ type: action.payload ? PaymentTypes.ADD_TO_PAYMENT : PaymentTypes.ADD_TO_PAYMENTS, payload: payments})
      if(payments && payments[0] && payments[0].payment_contract && payments[0].payment_contract.activity) {
        dispatch({ type: IntranetTypes.SET_PROJECT, payload: payments[0].payment_contract.activity.project_activity.project.name})
        dispatch({ type: ProcurementTypes.ADD_TO_PROCUREMENTS, payload: [payments[0].payment_contract.activity]})
      };
      if(action.payload) {
        dispatch({ type: PaymentTypes.UPDATE_INITIATED_PAYMENT})
        dispatch({ type: PaymentTypes.SET_ENTITY_INFO, payload: payments[0].payment_contract.entity_details})
      };
      dispatch({ type: PaymentTypes.REFRESH_INITIATED_PAYMENT})
    })
    .catch(err => {
        console.error(err);
    }).then(() => done()); 
  }
});

export const getPaymentLogic = createLogic({
  type: PaymentTypes.GET_PAYMENT,
  latest: true,
  async process({ httpClient, getState, action }, dispatch, done) {
    await httpClient.get(API.PAYMENT_TASK_DETAILS(action.payload))
    .then(resp => resp.data)
    .then(async payments => {
      if(payments && payments[0] && payments[0].payment_contract && payments[0].payment_contract.activity) {
        dispatch({ type: PaymentTypes.ADD_TO_PAYMENT, payload: payments})
        dispatch({ type: IntranetTypes.SET_PROJECT, payload: payments[0].payment_contract.activity.project_activity.project.name})
        dispatch({ type: ProcurementTypes.ADD_TO_PROCUREMENTS, payload: [payments[0].payment_contract.activity]})
        dispatch({ type: PaymentTypes.SET_ENTITY_INFO, payload: payments[0].payment_contract.entity_details})
      };
      dispatch({ type: PaymentTypes.REFRESH_INITIATED_PAYMENT})
    })
    .catch(err => {
        console.error(err);
    }).then(() => done()); 
  }
});

export const getPaymentRequestLogic = createLogic({
  type: PaymentTypes.GET_PAYMENT_REQUEST,
  latest: true,
  async process({ httpClient, getState, action }, dispatch, done) {
    await httpClient.get(API.PAYMENT_REQUEST_INFO(action.payload))
    .then(resp => resp.data)
    .then(async payments => {
      if(payments) {
        if(payments['payment_items']) {
          payments["paymentItems"] = payments['payment_items'];
          delete payments['payment_items'];
        }
        payments.customCurrenciesRate = {};
        payments.participated = [];
        if(payments.paymentItems.length > 0) {
          payments.paymentItems.forEach(payment => {
            payments.customCurrenciesRate[payment['currency_name']] = payment.exchange_rate;
            payment.rate = payment.exchange_rate;
          });
        }
        dispatch({ type: PaymentTypes.INITIATED_PAYMENT, payload: payments})
        dispatch({ type: PaymentTypes.ADD_TO_PAYMENT, payload: [payments]})
        dispatch({ type: IntranetTypes.SET_PROJECT_BY_ID, payload: payments.project_id})
        if(payments['payment_contract']) {
          dispatch({ type: ProcurementTypes.ADD_TO_PROCUREMENTS, payload: [payments.payment_contract.activity]})
          dispatch({ type: PaymentTypes.SET_ENTITY_INFO, payload: payments.payment_contract.entity_details})
        }
      };
      dispatch({ type: PaymentTypes.REFRESH_INITIATED_PAYMENT})
    })
    .catch(err => {
        console.error(err);
    }).then(() => done()); 
  }
});

export const getPaymentsNewRequestIDLogic = createLogic({
    type: PaymentTypes.GET_PAYMENT_REQUEST_ID,
    latest: true,
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient.post(API.PAYMENT_REQUEST, action.payload)
      .then(resp => resp.data)
      .then(async request => {
        dispatch({ type: PaymentTypes.UPDATE_REQUEST_ID, payload: request})
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
});

export const initiatePaymentRequestLogic = createLogic({
    type: PaymentTypes.INITIATE_PAYMENT_REQUEST,
    latest: true,
    async process({ httpClient, getState, action }, dispatch, done) {
      const {contractId, paymentId, initiatedPayment } = action.payload;
      await httpClient.post(API.INITIATE_PAYMENT_REQUEST(contractId, paymentId), initiatedPayment)
      .then(resp => resp.data)
      .then(async request => {
        dispatch({ type: PaymentTypes.UPDATE_COMMENTS_UUID, payload: request.comments_uuid})
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
});

export const getEntityInfoLogic = createLogic({
    type: PaymentTypes.GET_ENTITY_INFO,
    latest: true,
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient.get(API.GET_ENTITY(action.payload))
      .then(resp => resp.data)
      .then(async request => {
        dispatch({ type: PaymentTypes.SET_ENTITY_INFO, payload: request})
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
});

export const getFinanceOfficersLogic = createLogic({
    type: PaymentTypes.GET_FINANCE_OFFICERS,
    latest: true,
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient.get(API.GROUP_USERS_BY_ID(5))
      .then(resp => resp.data)
      .then(async request => {
        dispatch({ type: PaymentTypes.SET_FINANCE_OFFICERS, payload: request})
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
});

export const getPaymentFilesLogic = createLogic({
    type: PaymentTypes.GET_PAYMENT_FILES,
    latest: true,
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient.get(API.REQUEST_FILES(action.payload))
      .then(resp => resp.data)
      .then(async documents => {
        dispatch({ type: PaymentTypes.SET_PAYMENT_FILES, payload: documents.data})
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
  });

export const deletePaymentFileLogic = createLogic({
    type: PaymentTypes.DELETE_PAYMENT_FILE,
    latest: true,
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient.delete(API.DEL_PROCESS_FILE(action.payload))
      .then(async () => {
        await httpClient.get(API.REQUEST_FILES(action.payload.requestId))
        .then(resp => resp.data)
        .then(async documents => {
          dispatch({ type: PaymentTypes.SET_PAYMENT_FILES, payload: documents.data})
        })
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
  });

export const getPaymentCommentsLogic = createLogic({
    type: PaymentTypes.GET_PAYMENT_COMMENTS,
    latest: true,
    async process({ httpClient, getState, action }, dispatch, done) {
      await httpClient.get(API.REQUEST_COMMENTS(action.payload))
      .then(resp => resp.data)
      .then(comments => {
        dispatch({ type: PaymentTypes.SET_COMMENTS, payload: comments})
      })
      .catch(err => {
          console.error(err);
      }).then(() => done()); 
    }
});
