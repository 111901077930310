import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import setInitiatedContent, { getCountries, getCurrencyRates } from './redux/intranet/intranet.actions';
import { setCurrentUser, setCurrentUserToken } from './redux/user/user.actions';
import axios from 'axios';
// import { API } from './config/api';

import './scss/style.scss'; 
import { clearNotifications } from './redux/notification/notification.actions';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

//Shadi Token
// const AUTH_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjE2YmQwNzlkNjZlMmUyNjgxMmVkZjYyNDgyYzFjODcyM2UzMzM1NTc0NWExMGI3NGI2MjMxM2QxODM5NTAyZDBkNDZmNTJiMmZjYzk1MDU3In0.eyJhdWQiOiIxIiwianRpIjoiMTZiZDA3OWQ2NmUyZTI2ODEyZWRmNjI0ODJjMWM4NzIzZTMzMzU1NzQ1YTEwYjc0YjYyMzEzZDE4Mzk1MDJkMGQ0NmY1MmIyZmNjOTUwNTciLCJpYXQiOjE1OTczMjcwOTUsIm5iZiI6MTU5NzMyNzA5NSwiZXhwIjoxNjI4ODYzMDk1LCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.YqMDivkRyPFGnIWLNAXSGhpJduhDER8K1J_YJeZd95KdM4RSqPbRoILUkVZKe1HkCbSjdXTbqlb2kQeAyeqy8C-bT_6TGNyCicFLYVe9ZQdhXf1nhlSnk9SYF6F3iQTBNPkl1JknDxewSwdeLuw28rye7g5zVYZC-bnWhmMdgKpGQmdIX2C5KQlTDMtj5g9Y3qY46Mw4kjBXG5ABsYphMldzp0j3OAKN0I44MSlvVN1hPqTafHemMq3SgK4s84m5itNqBERyKF1PEGVcT6dfkXt2QPnVarf8mSthQZXIMGifj2ipJymSO-cUq8r9yNuOYmYgRMlLYPWh2sobugAJr3msoxbK2Q3tWBMD6oJuMowTvHoDFh9TR5r6vDKqaeyDJWrbV5tKTyN4zMHfmrz1V127dKiSpBz8a0__-vMENumlj9yQGf1y80vNTHzJaUn_HRRGeXovc0VZR6ExQ2I2YVu5l_3pirM1ZPl3T1ssxABS_cfRF3RrkHMk0-6W19tL7m5ZNhFQPnXmYvP8HuWgTBJKj9Obzlwe0kZckoxjL2qlmwyKxj1mupj4iFmR55RWaIjBf-vJ-YLpuyahn4PQbRtbMQaoN8ByvSHAV8wmjK4JwN09ZxWJHI13Ac1Ij_ubahbZJDGmLGfYzWqU5vR8kHMwVOU1F5MEDILEHQMrxsU';
//Ziad Token
// const AUTH_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImU3YWMxZmZjNmNlZDk0YTUwZGEzNzVkN2YyYmE4MzY5ODRjNzZjOTEwMmZiYWQ1YjM3ZjQ3OTYxZDQ2MDI4NzU0NzZjODlhZDgyZjExNzE3In0.eyJhdWQiOiIxIiwianRpIjoiZTdhYzFmZmM2Y2VkOTRhNTBkYTM3NWQ3ZjJiYTgzNjk4NGM3NmM5MTAyZmJhZDViMzdmNDc5NjFkNDYwMjg3NTQ3NmM4OWFkODJmMTE3MTciLCJpYXQiOjE1OTcyMzcyMzAsIm5iZiI6MTU5NzIzNzIzMCwiZXhwIjoxNjI4NzczMjMwLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.ZiRZp_TItp9oV8ntOXz6TFJ6NSfMAJnVlDc4w1LKeSMVzE0jhCiD16UdpYvnfTv18mN0hA_x8fOYJd8sO8DOMWOyn28uAdQZUzh29R6D1AcCiGN394IPVecHZiyGFjdSGqn1pcGL3bkr9oJnSl21GL22ySD7rWnV_SewSXQ8o6BWZOd7pCHrig_cpD1l7uxf7hV_EcaWRG_7Vy3ZnQ181u24JMY5RG1JgmlS0wHEMlghX9imkKnzaHRqJeGRSn4TeA0PL1RHiTiGXxNE1KechEweVIJ73tcBfXTxM8vTfCXMLOK2cRfX6U-_JSP548-Ylmc2LmJeHsauluFSF0KzoWpijRzOqeYQcOM4A5UudiHrB1byJ3SDOBxyMEEZMbTkpYtkxGZU-v5dJjzfjEGD9wFpuUA5oG7qcWPmatAArMMnJ_yCYp1v9lOaiI5hdf10v_eq34mqo3rDbbYYqxjKQs4ZMGlrijtFRQu9S4QpVwL580g632M7LJOJTjq5Rlz7mSTT9acrRXadhxVDFpNmaWt2qboquLOdOVR_hM5ZUw8mDZmp1uA1Ror8UeXFloli-UBK_fT4ZCtrFJ7KbFVUrYqoIPXBDpyHV1PwdyF7CD8ypzK6_c-LDw5ycTWrYPS3L-Hh4EubFR1LuaycAxxmqTO0O69YJrJVnA3lVmhSxP0';
//Admin Token
// const AUTH_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjkyMzkxMmRlOTNlZDBlMjE4MTc0MWE2ZjYzOWE3YTU4Nzg5NjYxOTU1ZDYzOTdlYjc2NGU2NTk4OTMyMmRlNTMzMDkwNTFmNTFkOWJhMTJjIn0.eyJhdWQiOiIxIiwianRpIjoiOTIzOTEyZGU5M2VkMGUyMTgxNzQxYTZmNjM5YTdhNTg3ODk2NjE5NTVkNjM5N2ViNzY0ZTY1OTg5MzIyZGU1MzMwOTA1MWY1MWQ5YmExMmMiLCJpYXQiOjE1OTcwNDUyNjcsIm5iZiI6MTU5NzA0NTI2NywiZXhwIjoxNjI4NTgxMjY3LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.JMOcjUoZLBOFRZTWvLJ36W-XeMrm8qMgXjHhuK0se7qaLc4qjS8CBUc0upZtRTcqa9okRExP2Qv2AqBaXRQZN0QDUjRmF8dX8j0ws4OHR1NYBMz2Wy2eEZIhNXaUIg0c-X1Zup0c-3TG8U_QypwIItX1l5h5OghSY3CQCACnjrc4rKM5LonckfOOZooAFLRrP8cBrgjJl26vzYGG9w7HdOOKv19kRef9oYi81RNXFwXBerssn8BOP1dVhyBqdbiWnWb8mZ81bhE33SYFONVwmm1QvQqSmuu3H6mUWC1vCINIB9ti2OGo4L_H8-wC4AAWTyLc52ZQeCRZ6xV8Y9wnV3rWOKDL0HGh4sRsK6eslkLYmqVlHnK73QDDjn8Caw6JLI5_PPlOtv9Kr0pn9cjU628R2gQ6KIPi7gaf6Zf2MQgL174pvcuVIJXqba46EnKfP7dvq0NBF01le3bgEn6ISPZy5iR6mPzYNfJPZIevUfbxvI1S3Gp9-wUK2f8fBdnrniOLh8X2r657IVeY1ZBUqiT3Ycgcjuz2jgIBviG3q-GpVKWt4zUMlUQsL5nqf_6L5IDpMcv-C7XjRSi9Q069xLm_2QBc2n3c3gmWA5E5XozvBEjvipVh9ecQaAPGYWvFXWKA-89VAJ3N3PlhYYdHIQXGXXbpB3gu57k7lDRmq0Y';
//Ibrahim
// const AUTH_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijg4NjU0ZDY3MTEyNjYwZTM1M2M5NDVmZDVhYTBmZmU4Nzg4ZjUyYjA5YmJmNGVhNGZiMGU4YzdiNzI2ZWMwMTM2MmQ5MThkNTY3M2Q0YThmIn0.eyJhdWQiOiIxIiwianRpIjoiODg2NTRkNjcxMTI2NjBlMzUzYzk0NWZkNWFhMGZmZTg3ODhmNTJiMDliYmY0ZWE0ZmIwZThjN2I3MjZlYzAxMzYyZDkxOGQ1NjczZDRhOGYiLCJpYXQiOjE1OTkzODI2NzEsIm5iZiI6MTU5OTM4MjY3MSwiZXhwIjoxNjMwOTE4NjcxLCJzdWIiOiI0Iiwic2NvcGVzIjpbXX0.Dp3cPkC0cqK7uk93hJFmVH6U0tHERuxEDPY6JOYCZLUmstNMPH7QdNWysZYUNzoUwcCSxocpn0HwLW8Yt2ktdtCleZNqmD_4Po59zApJItl8MxVn3KgCF8EGAtghObXgjggphmkfyOUV_4vZmDPVtbmZ7XAmqDRXslZnjhjFpps32WWLKMi3qEXyGfqVLtMJyvud679JbW71q8pMQ4VLB6pe9J-7y2Dkhu086u9zOtN-v-H956iJemx4VwqVQFDBBXLsK4nCX_2-estE2CAPkUhbsej_VSuc__6dRzx6EyqTrCLBstCbRmhQX1Xo4ieySz2XQkzwOuN1eLfxaj9sjWe163a7coC36JoLfI-DEvF7QlmgXvvIP3FJCU2szo_EPXUWbbImHVOsgdFvb2vY-JZ3iKezs3Lk7GS0ptI4rWIeHm8yGetDz08BiJXz7qaCbk6vmFK_haGQQEuYUiZvI8Xo0iIlj1kS6Q0ftQU2b9CGoqCxkelgopiRPLjmWc6r5AOgNi8xv9-EJlvObCNykhPrjeu2PEHtCitruJpUnD73Lkws94Kzjzkw4QyX5ip2dz2vEALaGMSrdWbLA9C_wylTehefSJSW10TdO0sTc8PkL_-yu4KuCOa8e8-QpmBd3mSEVNFJioEgOtOL_FY0BsD-e_tOGwI5jBMYp-oFD6w';
//Zaher
//  const AUTH_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjA0MzU5NmEwMTMxMWVmYjdhOWNhMjdhOGZjMDYwODljM2NhZjZmN2E3NWRhZmU2NGE4NmUwOTE0YzZiODMxZmM3NzRhYjFkZmYyZDczNzdlIn0.eyJhdWQiOiIxIiwianRpIjoiMDQzNTk2YTAxMzExZWZiN2E5Y2EyN2E4ZmMwNjA4OWMzY2FmNmY3YTc1ZGFmZTY0YTg2ZTA5MTRjNmI4MzFmYzc3NGFiMWRmZjJkNzM3N2UiLCJpYXQiOjE1OTkzODI3NzUsIm5iZiI6MTU5OTM4Mjc3NSwiZXhwIjoxNjMwOTE4Nzc1LCJzdWIiOiI1Iiwic2NvcGVzIjpbXX0.vSkbFx01WZa3P0oGa0aHqIj63cc4uiiGrRVJiW2vAfTroMXTcE2MKtD4cjcu397KpOymVJdw5-sv9p9PKqrwI-YP8r-U6S5124sFSSVKlPuYqbr8bDKxKTyKLbzhXH7qErfh58GHnxkHwWArT-KucsAZgdItmDYMgvo2T7un-gKU7YM0d5eKJzDz5uPfwmC-QpbWdEM3ayJGvzj_6Mf7P1Wfy85Z2n-uqDHH83A7gF4mfqHPzZs8azJez5tJi-YO8HNRB-j1E0F3JOzIw8d2NqAKpD6q-TP3Y-a5-iZKxdlLBJYWCNoXSyLBBdvyaMA2u-Oefr-E08hBVba1k78IbwKw43xQV6BfZFwN095IRaE9DjObQ9Oxc6issyErmCqHmujpCk9yYQvfs2F7gfJc8Z1SaRJfgXVr-yuZn7YgIGrkqAgocjWcwj9DmA-Lcp7Yk-fyn6AT768bjMFa-U_xDum4o5VBDPe798YX2g_6UcNwfzeuKFmt1gIs12z662evYCfErOWQqZSgYAT91oyrtw8YejEwmTiDAsMS2VS5zYxTjvqKZ8bSXmcZJeBhRpIWt4sAPXIYj9Ak9xABF-gZEytrf3rCH77hPW65gWvnDRhCI3cAeiDq1OK1SSfTAvv_lASsdZ4nmpEH-HTfpCI7aFDTpqg7_PKbxWluGAWRvD8';


class App extends Component {
  state = {
    loaded: false
  }
  async componentDidMount() {
    const { user } = this.props;
    // const { setInitiatedContent, setCurrentUser, setCurrentUserToken, clearNotifications, countries, getCountries, getCurrencyRates, user } = this.props;
    if(user.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
      // axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
      // await clearNotifications();
      // await getCurrencyRates();
      // let initiateIntranet = await axios.get(API.INTRANET + '/initiate');
      // let groupUsersUrl = API.GROUP_USERS_BY_ID(4);
      // let groupUsers = await axios.get(groupUsersUrl);  
      // let currentUser = await axios.get(API.INTRANET + '/me');
      // initiateIntranet.data.users = groupUsers.data.data;
      // setInitiatedContent(initiateIntranet.data);
      // setCurrentUser(currentUser.data);
      // // setCurrentUserToken(AUTH_TOKEN);
      // if(!countries.length) getCountries();
    }
    this.setState({
      loaded: true
    })
  }

  render() {
    const { loaded } = this.state;
    const { user } = this.props;
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              {(loaded) &&
                <Route path="/" name="Home" render={props => 
                  <>
                  {
                    user.token ? (
                      <TheLayout {...props}/>
                    ): (
                      <Redirect to='/login'/>
                    )
                  }
                  </>
                } />
              }
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.intranet.countries,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getCountries: () => dispatch(getCountries()),
  setInitiatedContent: content => dispatch(setInitiatedContent(content)),
  setCurrentUser: user => dispatch(setCurrentUser(user)),
  setCurrentUserToken: token => dispatch(setCurrentUserToken(token)),
  clearNotifications: () => dispatch(clearNotifications()),
  getCurrencyRates: () => dispatch(getCurrencyRates())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
